import { DEFAULT_LOGIN_PATH } from '@utils/auth'
import { PageProps, navigate } from 'gatsby'
import React, { FC, useEffect, useContext } from 'react'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import AuthContext from '@contexts/AuthContext'
import Seo from '@components/Seo'

const isBrowser = typeof window !== `undefined`

type LocationState = {
  [key: string]: any
}

const LogoutPage: FC<PageProps> = ({ location }) => {
  const { state }: LocationState = location
  const { doLogOut } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    doLogOut()

    if (state && state.alert) {
      enqueueSnackbar(state.alert.message, { variant: state.alert.variant })
    } else {
      enqueueSnackbar('You have successfully logged out', {
        variant: 'success'
      })
    }
    isBrowser && navigate(DEFAULT_LOGIN_PATH)
  }, [])

  return (
    <Box p={4}>
      <Seo title={'Logging you out...'} />
      <p>Logging you out...</p>
    </Box>
  )
}

export default LogoutPage
